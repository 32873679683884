<template>
  <div id="app">
    <div
      v-if="$root.ready && overflow"
      class="ready-overflow"
      @click="openWebsite()"
    >
      <button
        class="btn btn-outline-primary text-uppercase"
        @click="openWebsite()"
      >
        Buka Undangan
      </button>
    </div>

    <AGWAudio :file="music.file" :loop="music.loop" :autoplay="music.autoplay" ref="audio" />
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro" />

    <div class="agw-content bg-white" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro" />

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan" />
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes" />
      </div>

      <AGWCounter id="counter" class="pad-y-60" :background="background" :date="time.start" />
      <AGWGallery id="gallery" class="pad-y-60" />
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.716170347467!2d115.65371031478244!3d-8.429509193938141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwMjUnNDYuMiJTIDExNcKwMzknMjEuMiJF!5e0!3m2!1sen!2sid!4v1606440367480!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//-8.4295092,115.655899/@-8.4295092,115.6537103,17z/data=!4m2!4m1!3e0?hl=en"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ height: `${$root.dividerHeight}px` }"></div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format('YYYY') }}. Made by
        <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/purwa-eka/SKF_4642_L1-upRh2WTU.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/purwa-eka/SKF_4855_bA4YTslmdZio.jpg?tr=w-1024";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/purwa-eka/SKF_4580_5jcDCJvn1nG.jpg?tr=w-1024";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Br. Dinas Kaler, Desa Seraya, Kec. Karangasem, Kab. Karangasem - Bali",
      // date: "2020-04-05",
      time: {
        start: this.$moment("2021-01-01 13:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "I Wayan Purwantara, S.Pd",
          surname: "Purwa",
          parents: "I Wayan Pura & Ni Wayan Halus",
          description: "Putra pertama dari 2 bersaudara",
          address: "Br. Dinas Kaler, Desa Seraya, Kec. Karangasem, Kab. Karangasem - Bali",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/purwa-eka/SKF_4726_z9j8ncBMglC.jpg?tr=w-400",
        },
        woman: {
          name: "Ni Kadek Eka Suti Yadnyani, Amd. Kom",
          surname: "Eka",
          parents: "I Wayan Budi Yadnya & Ni Ketut Suti",
          address: "Br. Dinas Gerobog, Desa Seraya Barat, Kec. Karangasem & Kab. Karangasem - Bali",
          description: "Putri pertama dari 4 bersaudara",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/purwa-eka/SKF_4715_5LU7IMZ4Pdq9.jpg?tr=w-400",
        },
      },
      mepandes: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  },
};
</script>
